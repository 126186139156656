































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

@Component({
  components: {
  }
})
export default class AUser extends Vue {
  config = {
    jiaoyi: {
      0: '冻结',
      1: '入账',
      2: '兑换'
    },
    type: {
      0: '会员',
      1: '经纪人'
    },
    status: {
      0: '未核销',
      1: '已核销'
    }
  }
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  jifenType = '直客积分'
  search: any = {
    keyword: '',
    userId: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  tab(e: any, val: any) {
    if(e.target.tagName != 'INPUT') {return}
    if(val == 'zhike') {
      this.apiV = 'J27119'
    } else {
      this.apiV = 'J27330'
    }
    this.searchTable()
  }


  // 搜索 search:true 重置翻页
  apiV = 'J27119'
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0
      };
    }
    api
      .post(this.apiV, this.search)
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() => {
      this.loading = false;
    })
  }

  // 管理订单详情
  detail: any = []
  detailDialog = false
  toDetail(row: any) {
    api
      .post('A23004', {code: row.orderId})
      .then(res =>{
        this.detailDialog = true
        this.detail = [res]
      })
  }

  //列表状态查询
  jifenConfig = {}
  getStatusEnum() {
    api
      .post("J27110", {keyword: '3'})
      .then((data) => {
        this.jifenConfig = data
      });
  }

  activated() {
    this.search.userId = this.$route.query.userId
    // if(this.$route.query.keyword) {
    //   this.search.keyword = this.$route.query.keyword
    // } else {
    //   this.search.keyword = ''
    // }
    this.searchTable();
    this.getStatusEnum()
  }
}
